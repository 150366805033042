<template>
    <div>
        <van-loading class="vanLoading" type="spinner" color="#1989fa" v-if="loadingFlag" />
        <van-overlay class="vanOverlay" :show="loadingFlag"  />
    </div>
</template>

<script>
    import {loading,Overlay} from 'vant'
    export default {
        name: "loadingFlag",
        components: {
            [loading.name]: loading,
            [Overlay.name]: Overlay,
        },
        data() {
            return {
            loadingFlag : true
            }
        },
        methods:{
        }
    }
</script>

<style lang="less" scoped>
    .vanLoading{
        position: fixed;
        z-index: 3333;
        top: 50%;
        left: 47%;
    }
    .vanOverlay{
        z-index:2222;
    }
</style>